<!-- :items="$store.state.puntuaciones" -->
<template>
   <div class="ma-6">
      <Puntuacion @save="$refs.ok_noti.Show()" ref="puntuacion" />
      <Dialog message="¿Desea eliminar la puntuación?" @result_ok="confirmDeleteItem()" ref="deleteItem" />
      <Dialog message="¿Seguro que quiere mandar las imagenes de las puntuaciones?" @result_ok="confirmPuntuaciones()" ref="sendPuntuacion" />
      <Notification type="error" message="Error" ref="error_noti" />
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <v-data-table
         :loading="isloading"
         :headers="headers"
         :items="$store.state.puntuaciones.items"
         class="elevation-1 mb-8"
         :search="search"
         item-key="puntuacion_id"
         :page.sync="page"
         :options.sync="options"
         :server-items-length="totalItems"
         :footer-props="{
            'items-per-page-options': [10, 30, 50, 100]
         }"
      >
         <template v-slot:top>
            <v-toolbar flat color="secondary">
               <v-btn @click="newItem()"><v-icon left>mdi-plus-circle</v-icon>Nueva puntuación</v-btn>
               <v-btn @click="initialize" icon small class="ml-2"><v-icon small>mdi-autorenew</v-icon></v-btn>
               <v-btn @click="openPuntuacionesDialog()">Mandar urls</v-btn>
               <v-spacer></v-spacer>
               <v-text-field v-model="search" label="Search" clearable hide-details></v-text-field>
            </v-toolbar>
         </template>
         <template #[`item.wod`]="{ item }">
            <button @click="() => openPreviewPhoto(item)">
               {{ item.wod }}
            </button>
         </template>
         <template v-slot:[`item.categoria`]="{ item }">
            <v-icon small color="pink" v-if="item.categoria == 'F'">mdi-face-woman</v-icon>
            <v-icon small color="blue" v-else>mdi-face-man</v-icon>
         </template>
         <template v-slot:[`item.tipo`]="{ item }">
            <div v-if="item.tipo == 'T'">
               <v-icon small color="warning">mdi-clock-outline</v-icon>
               {{ secondsToMinutes(item.value) }}
            </div>
            <div v-else>
               <v-icon small color="success">mdi-repeat-variant</v-icon>
               {{ item.value }}
            </div>
         </template>
         <template v-slot:[`item.rango`]="{ item }">
            <div class="pretty_title" v-if="item.rango == 'R'">RX</div>
            <div class="pretty_title" v-else-if="item.rango == 'A'">Avanzado</div>
            <div class="pretty_title" v-else-if="item.rango == 'M'">Master</div>
            <div class="pretty_title" v-else-if="item.rango == 'I'">Intermedio</div>
            <div class="pretty_title" v-else-if="item.rango == 'K'">Kids</div>
            <div class="pretty_title" v-else>Principiante</div>
         </template>
         <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
         </template>
      </v-data-table>
      <PreviewPhoto ref="previewPhoto" />
   </div>
</template>

<script>
import Puntuacion from "./Puntuacion/Puntuacion.vue";
import PreviewPhoto from "./Puntuacion/PreviewPhoto.vue";

function str_pad_left(string, pad, length) {
   return (new Array(length + 1).join(pad) + string).slice(-length);
}

export default {
   components: {
      Puntuacion,
      PreviewPhoto,
   },
   data() {
      return {
         headers: [
            { text: "ID", value: "puntuacion_id" },
            { text: "Wod", value: "wod" },
            { text: "Atleta", value: "atleta" },
            { text: "Gym", value: "gym" },
            { text: "Categoria", value: "categoria" },
            { text: "Rango", value: "rango" },
            { text: "Tipo", value: "tipo" },
            { text: "Score", value: "score" },
            { text: "Acciones", value: "actions" },
         ],
         itemToDelete: null,
         isloading: true,
         search: "",
         totalItems: 0,
         page: 1,
         options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['puntuacion_id'],
            sortDesc: [true],
         },
         previousPage: 0,
      };
   },
   watch: {
      options: async function() {
         await this.initialize();
      },
      search: async function() {
         this.page = 1;
         this.options.page = 1;
         await this.initialize();
      }
   },
   methods: {
      async initialize() {
         try {
            this.isloading = true;
            await this.$store.dispatch("getPuntuaciones", {
               page: this.options.page - 1,
               itemsPerPage: this.options.itemsPerPage,
               pagination: true,
               sortBy: this.options.sortBy,
               sortDesc: this.options.sortDesc,
               search: this.search,
            });
            this.totalItems = this.$store.state.puntuaciones.totalItems;
            localStorage.setItem("puntuaciones_options", JSON.stringify(this.options));
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      async newItem() {
         this.$refs.puntuacion.Show({
            puntuacion_id: null,
            wod_id: null,
            atleta_id: null,
            value: null,
            options: {
               page: this.options.page - 1,
               itemsPerPage: this.options.itemsPerPage,
               pagination: true,
               sortBy: this.options.sortBy,
               sortDesc: this.options.sortDesc,
               search: this.search,
            },
         });
      },
      async editItem(item) {
         this.$refs.puntuacion.Show({
            puntuacion_id: item.puntuacion_id,
            wod_id: item.wod_id,
            atleta_id: item.atleta_id,
            value: item.value,
            options: {
               page: this.options.page - 1,
               itemsPerPage: this.options.itemsPerPage,
               pagination: true,
               sortBy: this.options.sortBy,
               sortDesc: this.options.sortDesc,
               search: this.search,
            },
         });
      },
      async deleteItem(item) {
         this.itemToDelete = item;
         this.$refs.deleteItem.Show();
      },
      async confirmDeleteItem() {
         try {
            this.isloading = true;
            let res = null;
            const options = {
               page: this.options.page - 1,
               itemsPerPage: this.options.itemsPerPage,
               pagination: true,
               sortBy: this.options.sortBy,
               sortDesc: this.options.sortDesc,
               search: this.search,
            };
            res = await this.$store.dispatch("deletePuntuacion", {
               puntuacion_id: this.itemToDelete.puntuacion_id,
               options, 
            });
            if (res == true) {
               this.$refs.ok_noti.Show();
            } else {
               throw res;
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      async openPuntuacionesDialog() {
         this.$refs.sendPuntuacion.Show();
      },
      async confirmPuntuaciones() {
         const url = this.$c.FRONTEND_URL;
         try {
            await this.$store.dispatch("sendAllPuntuaciones", { all: true, url });  
         } catch (error) {
            console.log(error);
         }
      },
      secondsToMinutes(time) {
         let minutes = Math.floor(time / 60);
         let seconds = time - minutes * 60;
         return str_pad_left(minutes, "0", 2) + ":" + str_pad_left(seconds, "0", 2);
      },
      async openPreviewPhoto({ puntuacion_id }) {
         this.$refs.previewPhoto.dialog = true;
         const response = await this.$store.dispatch("getImage", {
            puntuacion_id
         });
         if (
            response.data === "" 
            || response.data === undefined
            || response.data === null
         ) {
            this.$refs.previewPhoto.notFound();
            return;
         }
         this.$refs.previewPhoto.imageToShow = response.data.replaceAll("./", "");
         this.$refs.previewPhoto.showPhoto();
      },
   },
   created() {
      try {
         if (localStorage.getItem("puntuaciones_options")) {
            this.options = JSON.parse(localStorage.getItem("puntuaciones_options"));
            return;
         }

         this.options = {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['puntuacion_id'],
            sortDesc: [true],
         };
         localStorage.setItem("puntuaciones_options", JSON.stringify(this.options));
      } catch (error) {
         this.options = {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['puntuacion_id'],
            sortDesc: [true],
         };
         localStorage.setItem("puntuaciones_options", JSON.stringify(this.options));
      }
   },
};
</script>

<style scoped>
.pretty_title {
   font-size: 9px;
   font-weight: 900;
   text-transform: uppercase;
   letter-spacing: 2px;
}
</style>
