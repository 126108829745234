<template>
   <div>
      <Header />
      <Puntuaciones/>
      <Footer />
   </div>
</template>

<script>
import Puntuaciones from "../components/Puntuaciones/Puntuaciones.vue"
export default {
   components:{
      Puntuaciones
   }
};
</script>

<style></style>
