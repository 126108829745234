<template>
    <div>
        <Notification type="error" message="No se encontro una camara conectada" ref="errorNoti" />
        <v-btn @click="openPhotoScreen">
            <v-icon class="mr-2">mdi-camera</v-icon>
            Tomar Foto
        </v-btn>
        <v-dialog v-model="show" width="unset">
            <v-card>
                <v-card-title class="d-flex justify-center" v-if="!showMediaError">
                    Foto de {{ wod.text }}
                </v-card-title>
                <v-card-text>
                    <div class="d-flex justify-center aling-center mb-4">
                        <v-btn v-if="!showMediaError" @click="takePhoto" color="primary" class="mx-2">
                            <v-icon class="mr-2">mdi-camera</v-icon>
                            Tomar foto
                        </v-btn>
                    </div>
                    <v-row>
                        <v-col v-if="!showMediaError">
                            <video autoplay class="video-border"></video>
                        </v-col>
                        <v-col v-else>
                            <h1>Se necesita un host de tipo HTTPS para poder usar está funcion</h1>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <ImageVisualizer 
            :wod="wod" :atleta="atleta" ref="imageVisualizer"
            @close-dialog="close"
        />
    </div>
</template>

<script lang="js">
import ImageVisualizer from './ImageVisualizer.vue';

export default {
    components: {
        ImageVisualizer,
    },
    props: {
        atleta: Object,
        wod: Object,
    },
    data() {
        return {
            imageCapture: null,
            show: false,
            width: 1280,
            height: 720,
            camera: false,
            photoWidth: 1280,
            photoHeight: 720,
            showMediaError: false
        }
    },
    methods: {
        /**
         * Abre el dialogo donde se muestra un preview de lo que está viendo
         * la camara, además de seleccionar y pedir los permisos para usar
         * la camara.
         */
        openPhotoScreen() {
            this.show = true;
            this.showMediaError = false;
            /**
             * Es necesario `https` para poder usar `getUserMedia`
             */
            if (location.protocol !== "https:" && location.hostname !== "localhost") {
                this.showMediaError = true;
                return;
            }
            navigator.mediaDevices.getUserMedia({ video: true
            })
            .then(async (mediaStream) => {
                if(!mediaStream) {
                    alert("No se encuentra una camara conectada");
                    return;
                }
                document.querySelector('video').srcObject = mediaStream;
                const track = mediaStream.getVideoTracks()[0];
                this.imageCapture = new ImageCapture(track);
                this.camera = true;
                /**
                 * Checa las capacidades de la camara, obtiene y coloca el
                 * width y height de la foto al maximo posible de la camara
                 */
                const capabilities = await this.imageCapture.getPhotoCapabilities();
                this.photoWidth = capabilities.imageWidth.max;
                this.photoHeight = capabilities.imageHeight.max;
            })
            .catch(error => {
                if (this.$c.DEVELOP_MODE) 
                    console.log(error);

                this.$refs.errorNoti.Show();
                this.camera = false;
            });
        },
        /**
         * Abrre el visualizador de la imagen, además de pasarle la foto
         * tomada
         */
        takePhoto() {
            if (!this.camera) return;
            this.imageCapture.takePhoto({
                imageWidth: this.photoWidth,
                imageHeight: this.photoHeight,
            })
            .then(blob => createImageBitmap(blob))
            .then(imageBitmap => {
                this.$refs.imageVisualizer.previewImage(imageBitmap);
            });
        },
        close() {
            this.show = false;
            this.imageCapture = null;
            this.showSavePhoto = false;
        }
    },
};
</script>

<style>
.video-border {
    border: 1px solid red;
}
</style>