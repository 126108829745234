<template>
   <v-dialog v-model="dialog" max-width="600px">
      <Notification type="error" :message="errorMessage" ref="error_noti" />
      <Atleta @save="atletaAdded()" ref="atleta" />
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <v-card :loading="isloading">
         <v-card-title>{{ title }}</v-card-title>
         <v-card-text>
            <form>
               <v-row>
                  <v-col>
                     <v-autocomplete
                        v-model="wod"
                        label="Wod"
                        :error-messages="wodErrors"
                        required
                        :items="wods"
                        item-text="text"
                        item-value="value"
                        auto-select-first
                        return-object
                        @input="$v.wod.$touch()"
                        @blur="$v.wod.$touch()"
                     ></v-autocomplete>
                  </v-col>
                  <v-col class="d-flex align-center">
                     <v-autocomplete
                        ref="atleta_field"
                        v-model="atleta"
                        label="Atleta"
                        :error-messages="atletaErrors"
                        required
                        :items="atletas"
                        item-text="text"
                        item-value="value"
                        auto-select-first
                        return-object
                        @input="$v.atleta.$touch()"
                        @blur="$v.atleta.$touch()"
                     ></v-autocomplete>
                     <v-btn icon small @click="newAtleta()"><v-icon small>mdi-plus-circle</v-icon></v-btn>
                  </v-col>
               </v-row>
               <v-row>
                  <v-col cols="6" v-if="wod && wod.tipo == 'R'">
                     <v-text-field v-model="value" type="number" class="inputnum ma-2" outlined label="Repeticiones"></v-text-field>
                  </v-col>
                  <v-col cols="6" v-else-if="wod && wod.tipo == 'T'" class="d-flex align-center">
                     <v-text-field v-model="minutos" type="number" label="MIN" class="inputnum ma-2" outlined hide-details></v-text-field>
                     :<v-text-field v-model="segundos" type="number" label="SEC" class="inputnum ma-2" outlined hide-details></v-text-field>
                  </v-col>
                  <v-col cols="6" v-else></v-col>
                  <v-col v-if="atleta">
                     <TakePhoto :atleta="atleta" :wod="wod" />
                  </v-col>
                  <v-col v-if="atleta">
                     <div>
                        <v-icon small color="pink" v-if="atleta.categoria == 'F'">mdi-face-woman</v-icon>
                        <v-icon small color="blue" v-else>mdi-face-man</v-icon>
                     </div>
                     <div>
                        <div class="pretty_title" v-if="atleta.rango == 'R'">RX</div>
                        <div class="pretty_title" v-else-if="atleta.rango == 'A'">Avanzado</div>
                        <div class="pretty_title" v-else-if="atleta.rango == 'M'">Master</div>
                        <div class="pretty_title" v-else-if="atleta.rango == 'I'">Intermedio</div>
                        <div class="pretty_title" v-else-if="atleta.rango == 'K'">Kids</div>
                        <div class="pretty_title" v-else>Principiante</div>
                     </div>
                     <div>
                        {{ atleta.gym }}
                     </div>
                  </v-col>
                  
               </v-row>
            </form>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">Cancelar</v-btn>
            <v-btn :loading="isloading" color="secondary" @click="save(false)">Guardar</v-btn>
            <v-btn :loading="isloading" color="primary" @click="save(true)">Guardar y Nuevo</v-btn>

         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Atleta from "../../Atletas/Atletas/Atleta.vue";
import TakePhoto from "./TakePhoto.vue";

export default {
   components: {
      Atleta,
      TakePhoto,
   },
   mixins: [validationMixin],
   validations: {
      wod: {
         required,
         duplicate: function () {
            if (this.wod && this.atleta) {
               return !this.puntuaciones.some(
                  (elem) => 
                     elem.atleta_id == this.atleta.value && elem.wod_id == this.wod.value && !this.isEdit
               );
            }
            return true;
         },
      },
      atleta: {
         required,
         duplicate: function () {
            if (this.wod && this.atleta) {
               return !this.puntuaciones.some(
                  (elem) =>
                     elem.atleta_id == this.atleta.value && elem.wod_id == this.wod.value && !this.isEdit
               );
            }
            return true;
         },
      },
   },
   data: function () {
      return {
         puntuacion_id: null,
         wod: null,
         atleta: null,
         minutos: null,
         segundos: null,
         value: null,
         dialog: false,
         isloading: false,
         atletas: [],
         wods: [],
         puntuaciones: [],
         options: {
            page: 0,
            itemsPerPage: 10,
            pagination: true,
            sortBy: ['puntuacion_id'],
            sortDesc: [true],
            search: "",
         },
         errorMessage: "Error"
      };
   },
   watch: {
      dialog: function (val) {
         if (!val) {
            this.close();
            this.$emit("close");
         }
      },
   },
   methods: {
      obtenerImagen: function () {
         let image = JSON.parse(localStorage.getItem('imageToSave'));
         if (!image) {
            image = {
               atleta_id: this.atleta.value,
               imageURL: null,
               wod: this.wod.text.replaceAll(" ", "")
            };
         }
         return image;
      },
      save: async function (save_new) {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = null;

               if (this.wod.tipo == "T") {
                  this.value = +this.segundos + this.minutos * 60;
               }
               if (!this.isEdit) {
                  const image = this.obtenerImagen();
                  const options = {
                     page: this.options.page,
                     itemsPerPage: this.options.itemsPerPage,
                     pagination: true,
                     sortBy: this.options.sortBy,
                     sortDesc: this.options.sortDesc,
                     search: this.options.search,
                  };
                  res = await this.$store.dispatch("insertPuntuacion", {
                     atleta_id: this.atleta.value,
                     wod_id: this.wod.value,
                     value: this.value,
                     wod: image.wod,
                     imageURL : image.imageURL,
                     options
                  });
                  await this.$store.dispatch("insertImage", image)
               } else {
                  const image = this.obtenerImagen();

                  image.puntuacion_id = this.puntuacion_id;
                  const options = {
                     page: this.options.page,
                     itemsPerPage: this.options.itemsPerPage,
                     pagination: true,
                     sortBy: this.options.sortBy,
                     sortDesc: this.options.sortDesc,
                     search: this.options.search,
                  };
                  res = await this.$store.dispatch("updatePuntuacion", {
                     puntuacion_id: this.puntuacion_id,
                     atleta_id: this.atleta.value,
                     wod_id: this.wod.value,
                     value: this.value,
                     options
                  });
                  await this.$store.dispatch("updateImage", image);
               }
               if (res == true) {
                  if (save_new) {
                     this.$refs.ok_noti.Show();
                     await this.get_puntuaciones();
                     this.atleta = null;
                     this.value = null;
                     this.minutos = null;
                     this.segundos = null;
                     this.$v.$reset();
                  } else {
                     this.$emit("save");
                     this.close();
                  }
               } else {
                  if (res.response.status === 302)
                     this.errorMessage = "Puntuación duplicada";
                  throw res;
               }
            } else {
               throw "error";
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      close: function () {
         this.dialog = false;
         this.$v.$reset();
         localStorage.removeItem("imageToSave");
      },
      Show: async function (i) {
         this.dialog = true;
         this.minutos = null;
         this.segundos = null;
         this.value = 0;
         this.puntuacion_id = i.puntuacion_id;
         this.atleta = null;
         this.wod = null;
         await this.get_puntuaciones();
         await this.get_wods();
         await this.get_atletas();

         let wod = null;
         if (i.wod_id) {
            wod = this.wods.find((elem) => elem.value == i.wod_id);
         }
         if (wod) {
            this.wod = wod;
         }
         let atleta = null;
         if (i.atleta_id) {
            atleta = this.atletas.find((elem) => elem.value == i.atleta_id);
         }
         if (atleta) {
            this.atleta = atleta;
         }

         if (this.wod && i.value) {
            if (this.wod.tipo == "T") {
               this.minutos = Math.floor(i.value / 60);
               this.segundos = i.value - this.minutos * 60;
            } else if (this.wod.tipo == "R") {
               this.value = i.value;
            }
         }
      },
      get_puntuaciones: async function () {
         try {
            this.isloading = true;
            const options = {
               page: this.options.page,
               itemsPerPage: this.options.itemsPerPage,
               pagination: true,
               sortBy: this.options.sortBy,
               sortDesc: this.options.sortDesc,
               search: this.options.search,
            };
            await this.$store.dispatch("getPuntuaciones", options);
            this.puntuaciones = [];
            this.$store.state.puntuaciones.forEach((element) => {
               this.puntuaciones.push({
                  atleta_id: element.atleta_id,
                  wod_id: element.wod_id,
               });
            });
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      get_wods: async function () {
         try {
            this.isloading = true;
            await this.$store.dispatch("getWods");
            this.wods = [];
            this.$store.state.wods.forEach((element) => {
               this.wods.push({
                  value: element.wod_id,
                  text: element.nombre,
                  tipo: element.tipo,
               });
            });
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      get_atletas: async function () {
         try {
            this.isloading = true;
            await this.$store.dispatch("getAtletas");
            this.atletas = [];
            this.$store.state.atletas.forEach((element) => {
               this.atletas.push({
                  value: element.atleta_id,
                  text: `${element.nombre} [${element.num}]`,
                  categoria: element.categoria,
                  rango: element.rango,
                  gym: element.gym,
               });
            });
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      newAtleta() {
         this.$refs.atleta.Show({
            atleta_id: null,
            nombre: "",
            categoria: "V",
            rango: "P",
            gym: "",
            telefono: "",
         });
      },
      atletaAdded() {
         this.$refs.ok_noti.Show();
         this.get_atletas();
      },
   },
   computed: {
      isEdit() {
         if (this.puntuacion_id) {
            return true;
         } else {
            return false;
         }
      },
      title() {
         if (this.puntuacion_id) {
            return "Editar puntuación";
         } else {
            return "Crear puntuación";
         }
      },
      wodErrors() {
         const errors = [];
         if (!this.$v.wod.$dirty) return errors;
         !this.$v.wod.required && errors.push("El Wod es requerido");
         !this.$v.wod.duplicate && errors.push("Puntuacion duplicada");
         return errors;
      },
      atletaErrors() {
         const errors = [];
         if (!this.$v.atleta.$dirty) return errors;
         !this.$v.atleta.required && errors.push("El Atleta es requerido");
         !this.$v.atleta.duplicate && errors.push("Puntuacion duplicada");
         return errors;
      },
   },
};
</script>

<style>
.inputnum >>> input[type="number"] {
   -moz-appearance: textfield;
}
.inputnum >>> input::-webkit-outer-spin-button,
.inputnum >>> input::-webkit-inner-spin-button {
   appearance: none;
   -webkit-appearance: none;
   -moz-appearance: none;
}
</style>
