<template>
    <div>
        <v-dialog v-model="show" fullscreen>
            <v-card>
                <v-card-title class="d-flex justify-center">¿Guardar la foto?</v-card-title>
                <v-card-actions class="d-flex justify-center">
                    <v-btn color="red" @click="closeVisualizer">
                        <v-icon color="white">mdi-close</v-icon>
                    </v-btn>
                    <v-btn color="green" @click="savePhoto">
                        <v-icon color="white">mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
                <v-card-text>
                    <h1 v-if="loading" class="text-center my-4">Espere un momento</h1>
                    <canvas id="canvasImageVisualizer" style="width: 1280px; height: 720px;"></canvas>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        wod: "",
        atleta: "",
    },
    data() {
        return {
            show: false,
            loading: false,
        };
    },
    methods: {
        previewImage(imageBitmap) {
            this.show = true;
            this.loading = true;
            setTimeout(() => {
                const canvas = document.querySelector('#canvasImageVisualizer');
                this.drawCanvas(canvas, imageBitmap);
                this.loading = false;
            }, 1000);
        },
        // Metodo para previsualizar la imagen en el dialog
        drawCanvas(canvas, img) {
            canvas.width = getComputedStyle(canvas).width.split('px')[0];
            canvas.height = getComputedStyle(canvas).height.split('px')[0];
            let ratio  = Math.min(canvas.width / img.width, canvas.height / img.height);
            let x = (canvas.width - img.width * ratio) / 2;
            let y = (canvas.height - img.height * ratio) / 2;
            canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
            canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height,
            x, y, img.width * ratio, img.height * ratio);

        },
        closeVisualizer() {
            this.show = false;
        },
        /**
         * Guarda la foto en el localstorage, para despues
         * ser usada al momento de crear o editar una puntuacion.
         */
         savePhoto() {
            const canvas = document.querySelector('#canvasImageVisualizer');
            const imageURL = canvas.toDataURL("image/jpeg");
            // Removes the spaces from the wod
            let { text } = this.$props.wod;
            text = text.replaceAll(" ", "");
            
            const { value } = this.$props.atleta;
            const obj = {
                imageURL,
                atleta_id: value,
                wod: text
            };
            localStorage.setItem('imageToSave', JSON.stringify(obj));
            this.imageCapture = null;
            this.show = false;
            this.$emit("close-dialog");
        },
    },
};
</script>